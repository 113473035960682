<template>
    <div>
     <mdb-card cascade>
        <mdb-view class="card-header-reversed pl-2 pt-1 pb-1 d-flex justify-content-between align-items-center " cascade>
            <div class="title-card-tec card-header-title color-primary-reversed" style="font-weight:400">Modeles Factures </div>
            <mdb-btn
                class="float-right"
                tag="a"
                color="primary"
                icon="plus"
                size="sm" 
                small
                @click="addOrEdit(null)"
            >
            </mdb-btn>
        </mdb-view>

        <mdb-card-body :class="'card-reversed'" cascade v-loading="loading">
            <mdb-row>
                <mdb-col sm="12" md='4' class="mb-n4">
                <mdb-select flipOnScroll
                    outline
                    class=""
                    @getValue="nbrPerPageSelected"
                    v-model="dataPagination.pagination.perPageOptions"
                />
                </mdb-col>
                <mdb-col sm="12" md='4' class="mb-n4">
                </mdb-col>
                <mdb-col sm="12" md='4' class="mb-n3">
                <mdb-input  wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                    icon="search"
                    outline
                    type="text"
                    v-model="dataPagination.searchQuery"
                    placeholder="Rechercher"
                    aria-label="Search"
                    />
                </mdb-col>
            </mdb-row> 

            <mdb-tbl responsiveMd >
                <mdb-tbl-head>
                    <tr class="color-normal-reversed">
                        <th v-for="column in data.columns" :key="column.name" >
                            {{column.label}}
                        </th>
                    </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                    <tr class="tec-table-row  color-normal-reversed"  v-for="row in queriedData" :key="row.name">
                         <td>
                            <img width="30" :src="$store.state.url+'storage/models/'+row.image"> 
                         </td>

                        <td class="tec-table-cell" >  
                        <span v-if="row.is_active === 1" class="badge rounded-pill bg-success" style="height:10px; width:10px;display:inline-block"></span> 
                        <span v-if="row.is_active === 0" class="badge rounded-pill bg-danger" style="height:10px; width:10px;display:inline-block"></span> 
                         {{ row.code_model }} / {{ row.name }}</td>
                         <td :style="'color:'+row.theme_color+';font-weight:600;'">{{ row.theme_color }}</td>
                         <td>{{ row.width }} </td>
                         <td>{{ row.height }} </td>
                         <td>{{ row.type }} </td>
                        <td class="d-flex justify-content-end">
                            <el-dropdown trigger="click">
                                <span class="el-dropdown-link" style="cursor:pointer; font-weight:500" >
                                    Actions<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu class="dropdown-el-tec" slot="dropdown">
                                    <el-dropdown-item @click.native="addOrEdit(row)" class="dropdown-item-el-tec" icon="el-icon-edit">Modifier</el-dropdown-item>
                                    <el-dropdown-item  @click.native="deleteAccount(row.id)" class="dropdown-item-el-tec" icon="el-icon-delete">Supprimer</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </td>
                    </tr>
                </mdb-tbl-body>
            </mdb-tbl>
            <div v-if="empty" >
                <div class="d-flex justify-content-around align-items-center color-normal-reversed" style="padding:50px;">
                    <div>
                    <h5>Commencez dès maintenant à ajouter vos premiers models de facture !</h5>
                    <p>Pour ajouter un model, cliquez sur le bouton <mdb-btn class="z-depth-0" style="cursor:inherit" tag="a" color="primary" icon="plus" size="sm"  small ></mdb-btn>  en haut à votre droite. </p>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                 <tec-pagination
                    v-model="dataPagination.pagination.currentPage"
                    :per-page="parseFloat(dataPagination.pagination.perPage)"
                    :total="dataPagination.pagination.total"
                ></tec-pagination>
            </div>
        </mdb-card-body>
     </mdb-card>

    <mdb-modal
        centered
        size="lg"
        direction="top"
        position="top"
        :show="modelModelInvoice.show"
    >
        <form @submit.prevent="modelModelInvoice.edit ? update() : save()" >

        <mdb-modal-body class="modal-tec card-reversed">
                <div class="modal-times-tec "  @click="modelModelInvoice.show = false"> <i class="fas fa-times"></i> </div>
                <mdb-modal-title class="color-primary-reversed" style="font-weight:500 !important; font-size:1.5rem !important;">{{ modelModelInvoice.title }}</mdb-modal-title><br>
                <fieldset>
                    <mdb-row>
                        <mdb-col sm="12" md="2">
                            <input v-model="form.theme_color" type="color" class="form-control form-control-color" id="exampleColorInput" value="#563d7c" title="Choose your color">
                        </mdb-col>
                        <mdb-col sm="12" md="10">
                        </mdb-col>
                        <mdb-col sm="12" md="3">
                            <mdb-input  wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                               :validation="$store.state.model_invoice.errors.code_model ? true :false" :invalidFeedback="$store.state.model_invoice.errors.code_model"
                                v-model="form.code_model"
                                @change="codeModelFormatter"
                              size="lg" outline label="Code Model" ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="9">
                            <mdb-input  wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                               :validation="$store.state.model_invoice.errors.name ? true :false" :invalidFeedback="$store.state.model_invoice.errors.name"
                                v-model="form.name"
                                @change="nameFormatter"
                              size="lg" outline label="Nom" ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="6">
                            <mdb-input  wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                                v-model="form.width"
                                type="number"
                              size="lg" outline label="Largeur( en point)"  ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="6">
                            <mdb-input  wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                                v-model="form.height"
                                type="number"
                              size="lg" outline label="Hauteur( en point)"  ></mdb-input>
                        </mdb-col>
                         <mdb-col sm="12" md="6">
                            <mdb-select flipOnScroll wrapperClass=""    labelClass=" color-normal-reversed" caretClass="color-primary-reversed "
                                v-model="companyOption" search @getValue="getCompany" selectable outline label="Entreprise"  ></mdb-select>
                        </mdb-col>
                    </mdb-row>
                </fieldset>
                <fieldset>
                    <legend>Avancé</legend>
                    <mdb-row>
                        <mdb-col sm="12" md="6" lg="4" class="" >
                            <label for="">Etat du modele?</label>
                            <mdb-switch v-model="form.is_active" offLabel="Désactivé" onLabel="Activé"  />
                        </mdb-col>
                        
                        <mdb-col sm="12" md="6" lg="4" class="" >
                            <label for="">Orientation du model?</label>
                            <mdb-switch v-model="form.is_landscape" offLabel="Portrait" onLabel="Paysage"  />
                        </mdb-col>
                        <mdb-col sm="12" md="6" lg="4"  class="" >
                            <label for="">Type de modèle?</label>
                            <mdb-switch v-model="form.is_free" offLabel="Payant" onLabel="Gratuit"/>
                        </mdb-col>
                    </mdb-row>
                </fieldset>
                <mdb-row>
                    <mdb-col sm="12" md="12" class="d-flex justify-content-end  mt-5">
                            <el-upload
                                class="upload-demo"
                                ref="upload"
                                accept="image/png"
                                :limit="1"
                                :action="''"
                                :auto-upload="false">
                                <el-button slot="trigger" size="small" type="primary">Choisir un fichier</el-button>
                                <div class="el-upload__tip color-normal-reversed" slot="tip">Fichiers jpg/png avec une taille inférieure à 500kb</div>
                            </el-upload>
                    </mdb-col>
                </mdb-row>
                 <div class="d-flex justify-content-end mt-5">
                    <mdb-btn size="md" color="grey" @click="modelModelInvoice.show = false">Fermer</mdb-btn>
                    <mdb-btn size="md" color="success" type="submit"  :disabled="modelModelInvoice.btn">
                        <span v-if="!modelModelInvoice.btn">Sauvegarder</span>
                        <span v-if="modelModelInvoice.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span v-if="modelModelInvoice.btn" class="pl-2">Loading...</span>
                    </mdb-btn>
                </div>
        </mdb-modal-body>
        </form>
    </mdb-modal>
    </div>
</template>

<script>
import TecPagination from '@/tba/components/Pagination' 
import {
    mdbCard,mdbCardBody,
    mdbView,
    mdbBtn,
    mdbSwitch,
    mdbTbl,mdbTblHead,mdbTblBody,
    mdbRow,mdbCol,
    mdbSelect,mdbInput,
    mdbModal,mdbModalTitle,mdbModalBody,
} from 'mdbvue'
export default {
    name:"code_model",
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - MODELES DE  FACTURE',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        mdbCard,mdbCardBody,
        mdbView,
        mdbBtn,
        mdbSwitch,
        mdbTbl,mdbTblHead,mdbTblBody,
        mdbRow,mdbCol,
        mdbSelect,mdbInput,
        mdbModal,mdbModalTitle,mdbModalBody,
        TecPagination, 
    },

    data(){
        return {
            dataPagination: {
                pagination: {
                    perPage: 5,
                    currentPage: 1,
                    perPageOptions: [
                        {text:"5",value:"5", selected: true},
                        {text:"10",value:"10"},
                        {text:"25",value:"25"},
                        {text:"50",value:"50"},
                        {text:"100",value:"100"},
                    ],
                    total: 0,
                },
                searchQuery: "",
                propsToSearch: [
                    "code_model",
                    "name",
                    "type",
                    "width",
                    "height",
                ],
            },

            loading:false,
            empty:false,
            showPicker: false,
            companies:[],
            companyOption:[],
            data:{
                columns: [
                    {
                        label: "#",
                        field: "code_model",
                        sort: true,
                    },
                    {
                        label: "Model",
                        field: "name",
                        sort: true,
                    },
                    {
                        label: "Couleur",
                        field: "theme_color",
                        sort: true,
                    },
                    {
                        label: "Largeur",
                        field: "width",
                        sort: true,
                    },
                    {
                        label: "Hauteur",
                        field: "height",
                        sort: true,
                    },
                    {
                        label: "",
                        field: "action",
                        sort: true,
                    },
                ],
                rows:[
                ]
            },

            urlUpload:"",
            
            form:{
                c:"",
                id:"",
                name:"",
                theme_color:"",
                image:"",
                code_model:"",
                margin_top:"0",
                width:"595",
                height:"842",
                is_active:true,
                is_free:true,
                is_landscape:false,
                type:'free',
            },

            modelModelInvoiceDetails: {
                show: false,
                edit: false,
                title: "Détails du modele",
                content: {},
            },
            modelModelInvoice: {
                show: false,
                edit: false,
                title: "Ajouter un modele",
                content: {},
                btn:false
            },
        }
    },

    methods:{
        async submitUpload() {
            let ext = this.$refs.upload.uploadFiles[0].raw.type.split('/')[1];
            let filename = new Date().getTime()+"."+ext;

            this.urlUpload = this.$store.state.url+'api/model_invoices/image/'+filename;
            this.$nextTick()
            this.form.image= filename
            this.$refs.upload.submit();
         },
        //Fonction de fonctionnement
        addOrEdit(data = null) {
            if (data instanceof Object) {
                this.modelModelInvoice.title = "Modifier un modele";
                this.modelModelInvoice.content = data;
                this.modelModelInvoice.edit = true;
                this.formRest(data);
            } else {
                this.modelModelInvoice.title = "Ajouter un modele";
                this.modelModelInvoice.content = {};
                this.modelModelInvoice.edit = false;
                this.cleanForm("model_invoice");
            }

            this.modelModelInvoice.show = true;
        },

        cleanForm(type) {
            if (type === 'model_invoice') {
				this.form.id = ""
                this.form.c = ""
                this.form.image = ""
                this.form.name = ""
                this.form.code_model = ""
                this.form.theme_color = ""
                this.form.width = "595",
                this.form.height = "842",
                this.form.margin_top = 0;
                this.form.is_free = true
                this.form.is_active = true
                this.form.company = ""
                this.form.is_landscape = false

                this.companyOption.forEach(el => {
                    el.selected = false
                })

			}
        },

        formRest(data) {
            this.form.id = data.id
            this.form.name = data.name
            this.form.image = data.image
            this.form.code_model = data.code_model
            this.form.theme_color = data.theme_color
            this.form.width = data.width
            this.form.height = data.height
            this.form.margin_top = data.margin_top
            this.form.is_free = data.type === 'free' ? true : false
            this.form.is_active = data.is_active === 1 ? true : false
            this.form.is_landscape = data.is_landscape === 1 ? true: false
            this.form.company = data.company
            this.companyOption.forEach(el => {
               if (el.value == data.company) {
                   el.selected = true
               }
            })
            
        },

        async loadModelInvoice () {
			this.$nprogress.start()
            this.loading = !this.loading
            await this.$store.dispatch('model_invoice/findAll',{
                mode: "super"
            })
            .then(response => {
                this.$nprogress.done()
                this.loading = !this.loading
                if (response.data.modeles.length === 0) {
                    this.empty = true
                }else{
                    this.empty = false
                }
                this.data.rows = response.data.modeles
            }).catch((error) => {
				this.$nprogress.done()
                this.loading = !this.loading
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        getCompany(value){
            this.form.company = value
        },

        async save() {
            let fd = new FormData();

            if (this.$refs.upload.uploadFiles[0]) {
                fd.append("file", this.$refs.upload.uploadFiles[0].raw, this.$refs.upload.uploadFiles[0].name)
            }
            fd.append('name', this.form.name);
            fd.append('code_model', this.form.code_model);
            fd.append('theme_color', this.form.theme_color);
            fd.append('is_active', (this.form.is_active) ? 1 :0);
            fd.append('is_landscape',( this.form.is_landscape) ? 1 :0);
            fd.append('width', this.form.width);
            fd.append('height', this.form.height);
            fd.append('type', (this.form.is_free === true) ? 'free' : 'paid');
            if(this.form.company){
                fd.append('company', this.form.company);
            }
            
            this.$store.commit('model_invoice/SET_CLEAN')
            this.modelModelInvoice.btn = !this.modelModelInvoice.btn


            await this.$store.dispatch('model_invoice/save', fd)
			.then((response) => {
				this.modelModelInvoice.show = false
                this.modelModelInvoice.btn = !this.modelModelInvoice.btn
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('model_invoice')
                this.loadModelInvoice()
			})
			.catch((error) => {
                this.modelModelInvoice.btn = !this.modelModelInvoice.btn
                if (error.response.data.errors) {
                    this.$store.commit('model_invoice/SET_NAME', error.response.data.errors.name) 
                    this.$store.commit('model_invoice/SET_COLOR', error.response.data.errors.theme_color) 
                    this.$store.commit('model_invoice/SET_CODE', error.response.data.errors.code_model) 
                    this.$store.commit('model_invoice/SET_IMAGE', error.response.data.errors.image) 
                }

                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			}) 
        },

        async update() {

            let fd = new FormData();

            if (this.$refs.upload.uploadFiles[0]) {
                fd.append("file", this.$refs.upload.uploadFiles[0].raw, this.$refs.upload.uploadFiles[0].name)
            }
            fd.append('id', this.form.id);
            fd.append('name', this.form.name);
            fd.append('code_model', this.form.code_model);
            fd.append('theme_color', this.form.theme_color);
            fd.append('is_active', (this.form.is_active) ? 1 :0);
            fd.append('is_landscape',( this.form.is_landscape) ? 1 :0);
            fd.append('width', this.form.width);
            fd.append('height', this.form.height);
            fd.append('type', (this.form.is_free === true) ? 'free' : 'paid');
            if(this.form.company){
                fd.append('company', this.form.company);
            }

            this.$store.commit('model_invoice/SET_CLEAN')
            this.modelModelInvoice.btn = !this.modelModelInvoice.btn
			await this.$store.dispatch('model_invoice/update', {data:fd,id:this.form.id})
			.then((response) => {
				this.modelModelInvoice.show = false
                this.modelModelInvoice.btn = !this.modelModelInvoice.btn
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('model_invoice')
                this.loadModelInvoice()
			})
			.catch((error) => {
                this.modelModelInvoice.btn = !this.modelModelInvoice.btn
                if (error.response.data.errors) {
                    this.$store.commit('model_invoice/SET_NAME', error.response.data.errors.name) 
                    this.$store.commit('model_invoice/SET_COLOR', error.response.data.errors.theme_color) 
                    this.$store.commit('model_invoice/SET_CODE', error.response.data.errors.code_model) 
                    this.$store.commit('model_invoice/SET_IMAGE', error.response.data.errors.image) 
                }
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },

        async deleteAccount(id){

            let res = await this.$store.dispatch("swal/doYouDelete");
            if (res.isDismissed) {
                return;
            }
			await this.$store.dispatch('model_invoice/delete',id)
                .then((response) => {
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
                    this.loadModelInvoice()
                })
                .catch(error =>{
                    this.$notify({
                           
                        message: error.response.data.message  || error.response.data.error,
                        type: 'danger'
                    })
                })
			
		},

        async loadCompanies () {
			this.$nprogress.start()
            this.companyOption =   [];
            await this.$store.dispatch('company/findAll')
            .then(response => {
                this.$nprogress.done()
                this.companies = response.data.companies

                this.companyOption.push({
                        text:"Aucune Entreprise",
                        value:""
                    })
                this.companies.forEach(el =>{
                    this.companyOption.push({
                        text:el.name,
                        value:el.id
                    })
                })
                
            }).catch((error) => {
				this.$nprogress.done()
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        // Systeme de datatable
        totalCalcul () {
            return this.dataPagination.pagination.total = this.data.rows.length;
        },

        searchQ () {
            if (!this.dataPagination.searchQuery) {
                // return this.pagedData;
                return new Error('Nothing To Search')
            }
            
            let result = this.data.rows.filter((row) => {
                let isIncluded = false;
                for (let key of this.dataPagination.propsToSearch) {
                    let rowValue = "";
                    rowValue = row[key].toString().toLowerCase();
                    if (rowValue.includes && rowValue.includes(this.dataPagination.searchQuery.toLowerCase())) {
                    isIncluded = true;
                    }
                }
                return isIncluded;
            });
            this.dataPagination.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },

        nbrPerPageSelected(value){
            let nbr = value;
            
            return this.nbrPerPage(nbr)
        },

        nbrPerPage (nbr) {
           return this.dataPagination.pagination.perPage = nbr
        },

        nameFormatter(){
            this.form.name = this.form.name.toUpperCase()
        },
        formatFormatter(){
            this.form.format = this.form.format.toUpperCase()

        },
        codeModelFormatter(){
            this.form.code_model = this.form.code_model.toUpperCase()
        }
    },

    created() {
        this.loadModelInvoice()
        this.loadCompanies()
    },

    computed: {
        pageData () {
            return this.data.rows.slice(this.from, this.to);
        },

        to() {
            let highBound = this.from + this.dataPagination.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },

        queriedData() {
            let result = this.searchQ();
            return (result instanceof Error) ? this.pageData : result;
        },

        from() {
            return this.dataPagination.pagination.perPage * (this.dataPagination.pagination.currentPage - 1);
        },

        total() { 
            return this.totalCalcul();
        },
    }
}
</script>