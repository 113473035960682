<template>
    <div class="container-fluid">
        <mdb-row>
            <mdb-col sm="12" md="9" class=" mb-4 mx-0 mx-auto">
            <model-invoice-index></model-invoice-index>
            </mdb-col>
        </mdb-row>
    </div>
</template>

<script>
import ModelInvoiceIndex from '@/tba/views/model_invoices/ModelInvoiceIndex'
import {
    mdbRow,mdbCol,
} from 'mdbvue'
export default {
    components:{
        mdbRow,mdbCol,
        ModelInvoiceIndex
    },
    data(){
        return {
            //
        }
    }
}
</script>